<template>
  <div>
    <head-layout
      :head-btn-options="headBtnOptions"
      head-title="角色信息"
      @head-configureRolesInBulk="configureRolesInBulk"
    ></head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :grid-row-btn="gridRowBtn"
      :table-loading="tableLoading"
      @row-configureRoles="configureRoles"
    >
    </grid-layout>
<!--    <el-dialog-->
<!--      :title="$t('cip.plat.sys.user.field.roleConfig') + 111"-->
<!--      append-to-body-->
<!--      :visible.sync="roleBox"-->
<!--      width="90%"-->
<!--      :height="dialogHeight"-->
<!--    >-->
      <CommonDialog
        v-if="roleBox"
        :showFullscreen="true"
        :dialogTitle="$t('cip.plat.sys.user.field.roleConfig')"
        width="90%"
        @cancel="roleBox = false"
        @confirm="submitRole"
      >
      <userDialog
        ref="userDialog"
        :userFrom="form"
        @submitList="submitList"
      ></userDialog>
<!--      <span slot="footer" class="dialog-footer">-->
<!--          <el-button @click="roleBox = false">{{-->
<!--              $t("cip.cmn.btn.celBtn")-->
<!--            }}</el-button>-->
<!--                <el-button type="primary" @click="submitRole">{{-->
<!--                    $t("cip.cmn.btn.defBtn")-->
<!--                  }}</el-button>-->
<!--        </span>-->
<!--    </el-dialog>-->
    </CommonDialog>
  </div>
</template>

<script>

import {roleGetList} from "@/api/system/user";
import CommonDialog from "@/components/CommonDialog";
let baseUrl = '/api/sinoma-system/dict/dictionary?code='
import {mapGetters} from "vuex";
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import GridLayout from "@/views/components/layout/grid-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import userDialog from "@/views/system/userDialog";

export default {
  name: "deptTable",
  components: {
    GridLayout,
    HeadLayout,
    userDialog,
    CommonDialog
  },
  props: {
    deptDicDataList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      tableLoading: false,
      tableData: [],
      roleBox: false,
      deptDialogList: []
      // deviceProtocol:this.verdict,
    };
  },
  computed: {
    dialogHeight() {
      const windowHeight = window.innerHeight
      const titleHeight = 50 // 标题高度
      const padding = 80 // 内边距
      let height = windowHeight - titleHeight - padding
      if (height < this.minHeight) {
        height = this.minHeight
      }
      console.log("heightheightheight", height)
      return `${height}px`
    },
    ...mapGetters(["permission"]),
    searchColumns() {
      return []
    },
    tableOptions() {
      return {
        addBtn: false,
        editBtn: false,
        column: [
          // {
          //   label: '组织编码',
          //   prop: 'protocolCode',
          //   overHidden: true,
          // },
          {
            label: '组织名称',
            prop: 'department',
            overHidden: true,
          },
          {
            label: '是否主组织',
            prop: 'isDefaultDept',
            labelWidth: 80,
            type: 'switch',
            align: "center",
            props: {
              label: "label",
              value: "value"
            },
            dataType: 'String',
            overHidden: true,
            cell: true,
            dicData: [

              {
                label: '否',
                value: "0"
              },
              {
                label: '是',
                value: "1",
              },
            ],
          },
          {
            label: '角色',
            prop: 'roleName',
            overHidden: true,
          },
        ],
      }
    },
    gridRowBtn() {
      let result = [];
      result.push({
        label: '配置角色',
        emit: "row-configureRoles",
        type: "button",
        icon: ""
      });

      return result

    },
    gridHeadBtn() {
      return []
    },
    headBtnOptions() {
      let result = [];
      result.push({
        label: '批量配置',
        emit: "head-configureRolesInBulk",
        type: "button",
        icon: "",
      });
      return result
    },
  },
  mounted() {
    // this.onLoad({});
  },
  methods: {
    rowSelect(row) {
      this.$emit('select-data', row);
    },
    configureRoles(row) {
      this.deptList = []
      var item = this.deptDicDataList;
      let data = [];
      data.push(row)
      data.forEach(e => {
        //查询上级部门
        //查询本级部门
        let findChildData = this.findChildData(item, e.departmentId);
        let find = {}
        find.departmentId = findChildData.id
        find.department = findChildData.title
        find.deptAncestor = findChildData.organizeIds
        find.isDefaultDept = e.isDefaultDept
        find.roleId = []
        find.roleName = []
        if (typeof e.roleId != "undefined") {
          find.roleId = e.roleId.split(",")
          find.roleName = e.roleName.split(",")
        }
        this.deptList.push(find);
        //查询出所有的部门
      })
      console.log("findfind789-------", this.deptList)
      this.roleBox = true;
      this.$nextTick(() => {
        this.$refs.userDialog.getDataList();
        this.$refs.userDialog.deptList = this.deptList;
      })
    },

    configureRolesInBulk() {
      if (this.$refs.gridLayOut.selectionList.length === 0) {
        this.$message.warning("请先勾选组织后再进行配置操作！");
      } else {
        let selectionList = this.$refs.gridLayOut.selectionList
        this.deptList = []
        var item = this.deptDicDataList;
        selectionList.forEach(e => {
          //查询上级部门
          //查询本级部门
          let findChildData = this.findChildData(item, e.departmentId);
          let find = {}
          find.departmentId = findChildData.id
          find.department = findChildData.title
          find.deptAncestor = findChildData.organizeIds
          find.isDefaultDept = e.isDefaultDept
          find.roleId = []
          find.roleName = []
          if (typeof e.roleId != "undefined") {
            find.roleId = e.roleId.split(",")
            find.roleName = e.roleName.split(",")
          }
          this.deptList.push(find);
          //查询出所有的部门
        })
        this.roleBox = true;
        this.$nextTick(() => {
          this.$refs.userDialog.getDataList();
          this.$refs.userDialog.deptList = this.deptList;
        })
      }
    },
    findChildData(tree, childId) {
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.id === childId) {
          // 如果当前节点就是目标子节点，直接返回其数据
          return node;
        } else if (node.children) {
          // 否则递归查找当前节点的子节点
          const childData = this.findChildData(node.children, childId);
          if (childData) {
            // 如果找到了目标子节点的数据，返回该数据
            return childData;
          }
        }
      }
      // 没有找到目标子节点的数据，返回 null
      return null;
    },
    deptTableList(list) {
      // 先将list和tableData的departmentId抽出来为Set
      const listSet = new Set(list.map(item => item.departmentId));
      const tableDataSet = new Set(this.tableData.map(item => item.departmentId));
      // 从tableData中过滤出与listSet有交集的元素
      const commonData = this.tableData.filter(item => listSet.has(item.departmentId))
      // 从list中过滤出与tableDataSet没有交集的元素
      const newData = list.filter(item => !tableDataSet.has(item.departmentId));
      // 将两个数组合并
      this.tableData = commonData.concat(newData)
      this.tableData.forEach((item, index) => {
        if (typeof item.$cellEdit == "undefined") {
          this.$refs.gridLayOut.getGrid().rowCell(item, index);
          this.$refs.gridLayOut.getGrid().toggleRowSelection(item, true);
        }
      })
    },
    submitRole(row) {
      var submit = this.$refs.userDialog.submit();
      const map = submit.reduce((acc, curr) => {
        acc[curr.deptId] = curr;
        return acc;
      }, {});
      console.log("submitsubmit", submit)
      let cc = []
      this.tableData.forEach(row => {
        if (typeof map[row.departmentId] != "undefined") {
          row.roleName = map[row.departmentId].roleName
          row.roleId = map[row.departmentId].roleId
          row.isDefaultDept = map[row.departmentId].isDefaultDept
          row.deptId = map[row.departmentId].departmentId
          row.deptAncestor = map[row.departmentId].deptAncestor
        }
      })
      cc = this.tableData
      console.log("this.tableDatathis.tableData", this.tableData)
      this.$refs.gridLayOut.$refs.grid.refreshTable();
      this.tableData = cc
      this.roleBox = false
    },
    /**
     * 提交数据接口
     * @returns {*[]}
     */
    submitData() {
      console.log("进行输出submitDatasubmitData----------", this.tableData)
      let data = []
      let theOrganizationIsUnique = 0
      let theOrganization = 0
      this.tableData.forEach(item => {
        if (item.isDefaultDept == "1") {
          theOrganization++
        }
      })
      if (theOrganization == "0") {
        this.$message({
          type: "error",
          message: this.$t("cip.cmn.msg.success.isDefaultDept")
        });
        throw new Error(this.$t("cip.cmn.msg.success.isDefaultDept"))
      }
      this.tableData.forEach((item) => {
        if (item.roleId == null) {
          this.$message({
            type: "error",
            message: this.$t("cip.cmn.msg.success.selectARole")
          });
          throw new Error(this.$t("cip.cmn.msg.success.selectARole"))
        }
        if (item.isDefaultDept == "1") {
          theOrganizationIsUnique++
        }
        if (theOrganizationIsUnique > 1) {
          this.$message({
            type: "error",
            message: this.$t("cip.cmn.msg.success.deptError")
          });
          throw new Error(this.$t("cip.cmn.msg.success.deptError"))
        }
        let cc = {
          deptAncestor: item.deptAncestor,
          deptId: item.departmentId,
          deptName: item.department,
          isDefaultDept: item.isDefaultDept,
          roleId: item.roleId,
        }
        data.push(cc)
      })
      return data;
    },
    deptTableroleList(list) {
      console.log("进行输出----list---------", list)
      this.tableData = list;
      this.tableData.forEach((item, index) => {
        this.$refs.gridLayOut.getGrid().rowCell(item, index);
        this.$refs.gridLayOut.getGrid().toggleRowSelection(item, true);
      })
    },
  }
};
</script>
<style>
</style>
